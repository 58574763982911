<template>
  <div class="book-user border-radius-6 box-shadow-light-grey padding-20">
    <div class="operation padding-bottom-20">
      <el-select
        v-if="$store.state.userInfo.id == 1"
        style="width: 180px; margin-right: 24px"
        v-model="params.adminUserId"
        filterable
        clearable
        @change="handlePageChange(1)"
        placeholder="选择用户"
      >
        <el-option
          v-for="item in userList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button @click="handlePageChange(1)">刷新</el-button>
      <el-button type="primary" @click="handleAdd">新增</el-button>
    </div>

    <div class="custom-table" v-loading="loading">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 300px)"
        ref="dataTable"
        tooltip-effect="light"
        v-loading="loading"
      >
        <el-table-column
          label="用户名"
          prop="name"
          width="100"
        ></el-table-column>
        <el-table-column
          label="系统缺少的"
          prop="novelLack"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="遇到的问题"
          prop="novelQuestion"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="不能忍受的"
          prop="novelFeel"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="系统的需求"
          prop="novelNeeds"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="创建时间" width="180" prop="createdTime">
          <template slot-scope="{ row }">
            <span>{{ row.createdTime | timeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="handleShowEdit(scope.row.createdTime || '')"
              class="button-small"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <!-- <el-button
              class="button-small"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <feedBack
      :showFeedBack="showFeedBack"
      :detail="editData"
      :isAdd="true"
      @fresh="handlePageChange(1)"
      @close="showFeedBack = false"
      @cancel="showFeedBack = false"
    />
  </div>
</template>
<script>
import feedBack from "@/components/feedBack";
import { mapGetters } from "vuex";
import { formatTime } from "@/assets/js/utils";
import {
  getUserFeedbackList,
  deleteUserFeedback,
  addUserFeedback,
} from "@/api/user.js";

export default {
  name: "userFeedback",
  data() {
    return {
      tableData: [
        {
          userName: "亚里士多德拖了个耶夫斯基",
        },
      ],
      loading: false,
      page: 1,
      pageSize: 15,
      total: 0,
      params: {},
      editData: null,
      showFeedBack: false,
    };
  },
  components: {
    feedBack,
  },
  computed: {
    ...mapGetters(["userList"]),
  },
  filters: {
    timeFilter(data) {
      return formatTime(data || "");
    },
  },
  methods: {
    handleShowEdit(data) {
      let tody = formatTime(new Date(), "yyyy-MM-dd");
      let tmpTime = formatTime(data, "yyyy-MM-dd");
      return tody == tmpTime;
    },
    handleAdd() {
      this.editData = null;
      this.showFeedBack = true;
    },
    handleEdit(data) {
      this.editData = {
        ...data,
      };
      this.showFeedBack = true;
    },
    handleDelete(data) {
      this.$confirm("确定要删除此条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUserFeedback.then((res) => {
          this.$message.success("删除成功");
        });
      });
    },
    handlePageChange(page = this.page) {
      this.page = page;
      this.loading = true;
      let tpmId = this.$store.state.userInfo.id;
      // let tpmId = 319;
      getUserFeedbackList({
        page: this.page,
        pageSize: this.pageSize,
        userId: this.params.adminUserId || null,
      })
        .then((res) => {
          this.total = res.total;
          this.tableData = res.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.handlePageChange(1);
  },
};
</script>
<style lang="scss" scoped>
.el-input {
  width: 200px;
}
.book-user {
  background-color: #fff;
}
.custom-filter {
  .filter-list {
    .filter-list-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 50px;
      > span {
        width: 150px;
      }
      > :nth-child(2) {
        flex-grow: 1;
      }
    }
  }
  .button-list {
    text-align: center;
  }
}
/deep/ .el-tooltip {
  padding: 0 10px !important;
}
</style>
